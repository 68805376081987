"use client";

import React, { useEffect } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { UserAccountNav } from "./UserAccountNav";
import { useUserContext } from "./providers/user-provider";
import { cn, getCredits, isAdminUser } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import Image from "next/image";
import { getPublicAssetPath } from "@/lib/asssetUtils";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { RiFacebookFill, RiLinkedinFill, RiYoutubeFill } from "react-icons/ri";
import { AppUser } from "@/lib/getCurrentUser";
import LanguageChanger from "@/lib/i18n/LanguageChanger";
import { useTranslation } from "react-i18next";
import { getPathWithoutLocale } from "@/lib/i18n/translationUtils";

export interface NavLink {
  href: string;
  label: string;
  adminOnly?: boolean;
  activePattern: string | string[];
  visibility: "always" | "authenticated" | "unauthenticated";
}

interface NavbarProps {
  user: AppUser;
  links?: NavLink[];
  logo?: React.ReactNode;
  defaultLogoWidth?: number;
  defaultLogoHeight?: number;
}

function Navbar({
  user,
  links = [
    {
      href: "/home",
      label: "Home",
      activePattern: "/home",
      visibility: "authenticated",
    },
    {
      href: "/games",
      label: "Games",
      activePattern: ["/game"],
      visibility: "authenticated",
    },
    {
      href: "/collections",
      label: "Collections",
      activePattern: "/collections",
      visibility: "authenticated",
    },
    {
      href: "/about_us",
      label: "About us",
      activePattern: "/about_us",
      visibility: "unauthenticated",
    },
    {
      href: "/pricing",
      label: "Pricing",
      activePattern: "/pricing",
      visibility: "unauthenticated",
    },
    {
      href: "/pricing",
      label: "Subscriptions",
      activePattern: "/pricing",
      visibility: "authenticated",
    },

    {
      href: "/login",
      label: "Sign in",
      activePattern: "/login",
      visibility: "unauthenticated",
    },
  ],
  logo = null,
  defaultLogoWidth = 68,
  defaultLogoHeight = 43,
}: NavbarProps) {
  const pathname = usePathname();
  const userContext = useUserContext();
  const isAdmin = isAdminUser(user);
  const logoURL = getPublicAssetPath("/img/logo-brand.svg");

  const { t } = useTranslation();

  const isLandingPage =
    pathname === "/" || pathname === "/en" || pathname === "/ro";

  const isLinkActive = (activePattern: string | string[]): boolean => {
    if (typeof activePattern === "string") {
      return getPathWithoutLocale(pathname) === activePattern;
    }
    return activePattern.every((pattern) =>
      pattern.startsWith("!")
        ? !getPathWithoutLocale(pathname).includes(pattern.slice(1))
        : getPathWithoutLocale(pathname).includes(pattern),
    );
  };

  const shouldRenderLink = (link: NavLink): boolean => {
    if (link.adminOnly && !isAdmin) return false;
    switch (link.visibility) {
      case "always":
        return true;
      case "authenticated":
        return !!user;
      case "unauthenticated":
        return !user;
      default:
        return false;
    }
  };

  const toggleSideNav = () => {
    userContext.setSideNavOpen((prev) => !prev);
  };

  useEffect(() => {
    userContext.setSideNavOpen(false);
  }, [pathname]);

  if (userContext.imageFullScreen) {
    return null;
  }

  return (
    <header
      className={cn(
        `header z-50 flex w-full relative shadow-custom justify-center items-center rounded-bl-2xl rounded-br-2xl h-header bg-white`,
        user ? "hidden sm:flex" : "",
      )}
    >
      {/* not logged in side menu */}
      {!user && (
        <div
          className={clsx(
            "fixed lg:hidden w-screen h-screen top-0 bottom-0 bg-black bg-opacity-60 flex flex-row transition-all duration-300",
            userContext.sideNavOpen ? "left-0" : "left-full",
          )}
        >
          <button className="flex-1 " onClick={toggleSideNav}></button>
          <div className="w-2/3 sm:w-1/2 p-6 bg-white rounded-tl-2xl rounded-bl-2xl shadow">
            <div className="p-4 sm:p-6 h-full w-full flex flex-col justify-between">
              <div className="flex flex-col gap-8 items-center">
                <button
                  className="self-stretch flex justify-end items-center"
                  onClick={toggleSideNav}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <Link href="/about_us">{t("about")}</Link>
                <Link href="/pricing">{t("pricing")}</Link>
                <Link
                  href="/login"
                  className="h-[42px] px-4 py-2 bg-brand-secondary rounded-lg border border-white flex justify-center items-center text-white text-sm font-semibold leading-tight"
                >
                  {t("signIn")}
                </Link>
              </div>
              <div className="flex flex-col gap-4">
                <div className="self-center">
                  <LanguageChanger showOnTop />
                </div>
                <div className="flex justify-center gap-11">
                  <Link
                    href="https://www.facebook.com/xChessai"
                    target="_blank"
                  >
                    <RiFacebookFill className="w-6 h-6 fill-slate-400" />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/xchess-ai/"
                    target="_blank"
                  >
                    <RiLinkedinFill className="w-6 h-6 fill-slate-400" />
                  </Link>
                  <Link
                    href="https://www.youtube.com/@xChessai"
                    target="_blank"
                  >
                    <RiYoutubeFill className="w-6 h-6 fill-slate-400" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* navbar */}
      <div className="h-full flex sm:h-fit max-w-[1440px] mx-6 py-1 items-center justify-between w-full ">
        <div className="flex w-full justify-between">
          <div className="flex items-center justify-between w-full ">
            <div className="flex-col flex lg:flex-row items-center">
              {logo || (
                <Link href="/">
                  <Image
                    src={logoURL}
                    width={defaultLogoWidth}
                    height={defaultLogoHeight}
                    alt={t("logoAlt")}
                  />
                </Link>
              )}
            </div>
            <div className="sm:flex gap-8 items-center text-slate-800 text-sm font-medium leading-tight">
              {!user && (
                <button
                  className="flex lg:hidden justify-center items-center"
                  onClick={toggleSideNav}
                >
                  <Bars3BottomRightIcon className="w-6 h-6" />
                </button>
              )}

              <div className="hidden sm:flex">
                <LanguageChanger />
              </div>
              <div className={clsx(user ? "flex" : "hidden", "lg:flex gap-6")}>
                {links.map(
                  (link, index) =>
                    shouldRenderLink(link) && (
                      <Link
                        key={index}
                        href={link.href}
                        className={clsx(
                          isLinkActive(link.activePattern)
                            ? "border-b font-bold text-brand-secondary border-brand-secondary"
                            : "",
                          link.label === "Sign in"
                            ? "h-[42px] px-4 py-2 bg-brand-secondary rounded-lg border border-white justify-center items-center gap-1 flex text-white text-sm font-semibold leading-tight"
                            : " flex items-center justify-center",
                        )}
                      >
                        {t(
                          link.label === "Sign in"
                            ? "signIn"
                            : link.label.split(" ")[0].toLowerCase(),
                        )}
                      </Link>
                    ),
                )}
              </div>
              {user && (
                <div className="flex items-center gap-4">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Link
                          className="flex justify-center items-center gap-4"
                          href="/pricing"
                        >
                          <SparklesIcon className="w-6 h-6 text-amber-500" />
                          {getCredits(user.apiLimit)}
                        </Link>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {t("credits.free", {
                            creditsNumber: user.apiLimit?.freeCredits ?? 0,
                          })}
                        </p>
                        <p>
                          {t("credits.plan", {
                            creditsNumber: user.apiLimit?.planCredits ?? 0,
                          })}
                        </p>
                        <p>
                          {t("credits.otp", {
                            creditsNumber: user.apiLimit?.otpCredits ?? 0,
                          })}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <UserAccountNav user={user} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
